<template>
  <v-container class="ma-0 pa-0">
    <span class="label deduction">{{ label }}: {{ value | float1_0 }}</span>
    <v-btn-toggle
      v-model="val_1"
      mandatory
      light
    >
      <v-btn class="v-btn--flat" small>0.</v-btn>
      <v-btn class="v-btn--flat" small>1.</v-btn>
      <v-btn class="v-btn--flat" small>2.</v-btn>
      <v-btn class="v-btn--flat" small>3.</v-btn>
      <v-btn class="v-btn--flat" small>4.</v-btn>
      <v-btn class="v-btn--flat" small>5.</v-btn>
      <v-btn class="v-btn--flat" small>6.</v-btn>
      <v-btn class="v-btn--flat" small>7.</v-btn>
      <v-btn class="v-btn--flat" small>8.</v-btn>
      <v-btn class="v-btn--flat" small>9.</v-btn>
      <v-btn class="v-btn--flat" small>10.</v-btn>
    </v-btn-toggle><br>
    <v-btn-toggle
      v-model="val_01"
      mandatory
      light
    >
      <v-btn class="v-btn--flat" small>.0</v-btn>
      <v-btn class="v-btn--flat" small>.1</v-btn>
      <v-btn class="v-btn--flat" small>.2</v-btn>
      <v-btn class="v-btn--flat" small>.3</v-btn>
      <v-btn class="v-btn--flat" small>.4</v-btn>
      <v-btn class="v-btn--flat" small>.5</v-btn>
      <v-btn class="v-btn--flat" small>.6</v-btn>
      <v-btn class="v-btn--flat" small>.7</v-btn>
      <v-btn class="v-btn--flat" small>.8</v-btn>
      <v-btn class="v-btn--flat" small>.9</v-btn>
    </v-btn-toggle>
  </v-container>
</template>

<script>
export default {
  name: 'punktewaehler',

  props: {
    value: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      requred: true
    }
  },

  computed: {
    val_1: {
      get () { return this.get_1() },
      set (val) { return this.set_1(val) }
    },
    val_01: {
      get () { return this.get_01() },
      set (val) { return this.set_01(val) }
    }
  },

  methods: {
    get_1 () {
      return Math.floor(this.value)
    },
    get_01 () {
      return Math.round((this.value - Math.floor(this.value)) * 10)
    },
    set_1 (val) {
      this.$emit('input', Math.round((this.value - Math.floor(this.value) + parseInt(val)) * 10) / 10)
    },
    set_01 (val) {
      this.$emit('input', Math.round(Math.floor(this.value) * 10 + parseInt(val)) / 10)
    }
  }
}
</script>

<style scoped>
.label {
  display: inline-block;
  width: 50px;
}

.label.deduction {
  display: block;
  width: 100%;
}
</style>
